/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100%;
  height: 100%;
}

.App {
  font-family: "Work Sans", sans-serif;
  text-align: center;
  width: 100%;
  max-width: 600px;
  min-height: 100%;
  color: #dbe7fc;
  padding: 1.5rem;
  background-image: linear-gradient(
    to bottom,
    #1336a6,
    #284cb8,
    #3a61ca,
    #4c77db,
    #5f8deb
  );
} */

.weather-row {
  display: grid;
  grid-template-columns: 1fr;
}

.weather-row > * {
  grid-column: 2/ -2;
}

.weather-row > .full {
  grid-column: 1/-1;
}

.weather-row .weather-grid {
  display: grid;
  grid-template-columns: repeat(5, calc(25% - 40px));
  grid-template-rows: minmax(150, 1fr);
  overflow-x: scroll;
  scrollbar-width: none;
  grid-gap: 20px;
}

.dme-viewmode-mobile .weather-row .weather-grid{
  grid-template-columns: repeat(5, calc(60% - 40px));
}

.weather-card {
  background: linear-gradient(180deg, #ffffff 0%, #cce0ff 100%);
  border-radius: 15px;
  box-shadow: 0px 2px 4px rgba(8, 35, 48, 0.24),
    0px 4px 8px rgba(8, 35, 48, 0.16);
  margin-bottom: 1.5rem;
}

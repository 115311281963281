@tailwind base;
@tailwind components;
@tailwind utilities;

:focus-visible {
  outline: none !important;
}

:root{
  --dmee-bottom-height: 20px;
}

img {
  display: inline-block !important;
}
.App {
  font-family: Arial;
}

body {
  margin: 0px;
}

.dme-blocktype-table a, .dme-blocktype-text a {
  color: rgb(27, 112, 41) !important;
}

.button-icon{
  display: inline-block;
  width: 0.85rem;
  height: 0.85rem;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
  margin-left: 5px;
}

.icon-more{
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBVcGxvYWRlZCB0bzogU1ZHIFJlcG8sIHd3dy5zdmdyZXBvLmNvbSwgR2VuZXJhdG9yOiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4NCjxzdmcgZmlsbD0iI2ZmZmZmZiIgaGVpZ2h0PSI4MDBweCIgd2lkdGg9IjgwMHB4IiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiANCgkgdmlld0JveD0iMCAwIDMzMCAzMzAiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggaWQ9IlhNTElEXzIyMl8iIGQ9Ik0yNTAuNjA2LDE1NC4zODlsLTE1MC0xNDkuOTk2Yy01Ljg1Ny01Ljg1OC0xNS4zNTUtNS44NTgtMjEuMjEzLDAuMDAxDQoJYy01Ljg1Nyw1Ljg1OC01Ljg1NywxNS4zNTUsMC4wMDEsMjEuMjEzbDEzOS4zOTMsMTM5LjM5TDc5LjM5MywzMDQuMzk0Yy01Ljg1Nyw1Ljg1OC01Ljg1NywxNS4zNTUsMC4wMDEsMjEuMjEzDQoJQzgyLjMyMiwzMjguNTM2LDg2LjE2MSwzMzAsOTAsMzMwczcuNjc4LTEuNDY0LDEwLjYwNy00LjM5NGwxNDkuOTk5LTE1MC4wMDRjMi44MTQtMi44MTMsNC4zOTQtNi42MjgsNC4zOTQtMTAuNjA2DQoJQzI1NSwxNjEuMDE4LDI1My40MiwxNTcuMjAyLDI1MC42MDYsMTU0LjM4OXoiLz4NCjwvc3ZnPg==")
}

.dme-blocktype-button a{
  text-align: center;
}

div[role='textbox'] h3, .dme-blocktype-text h3 {
  font-size: 1.35rem;
  font-weight: bold;
  margin: 1rem 0px;
}

div[role='textbox'] h2, .dme-blocktype-text h2 {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 1.1rem 0px;
}